import { Frontier } from '@coditory/frontier/dist/js/Frontier';
import * as quicklink from 'quicklink';
import '../style/main.scss';
import { EditLinksActivator } from './EditLinksActivator';

Frontier
  .loadAndActivate()
  .then(() => Promise.resolve());

new EditLinksActivator()
  .activate();

quicklink.listen();
