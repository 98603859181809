interface EditLinksActivatorEntry {
  enabled: boolean;
  date: Date;
}

export class EditLinksActivator {
  constructor(
    private readonly win: Window = window,
    private readonly doc: Document = win.document) {}

  public activate() {
    this.doc.querySelectorAll('input[type="checkbox"]#edit-page-links-activator')
      .forEach(element => this.handleActivationInput(element as HTMLInputElement));
    this.refreshLinks();
  }

  public enable() {
    this.changeState(true);
  }

  public disable() {
    this.changeState(false);
  }

  private handleActivationInput(element: HTMLInputElement) {
    element.checked = this.isEnabled();
    element.addEventListener('change', () => this.changeState(element.checked));
  }

  private refreshLinks() {
    const links = this.doc.querySelectorAll('.edit-page-link');
    if (this.isEnabled()) {
      links.forEach(element => this.showLink(element as HTMLElement));
    } else {
      links.forEach(element => this.hideLink(element as HTMLElement));
    }
  }

  private showLink(element: HTMLElement) {
    element.style.display = 'initial';
  }

  private hideLink(element: HTMLElement) {
    element.style.display = null;
  }

  private changeState(enabled: boolean) {
    const entry: EditLinksActivatorEntry = {
      enabled,
      date: new Date()
    };
    this.win.localStorage.setItem('EditLinksActivator', JSON.stringify(entry));
    this.refreshLinks();
  }

  private isEnabled(): boolean {
    const entry = this.win.localStorage.getItem('EditLinksActivator');
    if (!entry) return false;
    const parsed = JSON.parse(entry) as EditLinksActivatorEntry;
    return parsed.enabled;
  }
}